import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import TableProfile from "./data/TableListProfile";
import apiUrl from "domain";
import axios from "axios";
import { Drawer, Input, Upload, Space, Select, DatePicker } from "antd";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { UserContext } from "App";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Overview() {
  const [dataPerfil, setDataPerfil] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [editingIdentificacion, setEditingIdentificacion] = useState(false);
  const [editedDataIdentificacion, setEditedDataIdentificacion] = useState({});
  const user_id = useContext(UserContext);
  const [followsMe, setFollowsMe] = useState(true);
  const [empresaName, setEmpresaName] = useState('');
  const [empresaNIT, setEmpresaNIT] = useState('');
  const [tipoIdentificacion, setTipoIdentificacion] = useState('');
  const [identificacion, setIdentificacion] = useState('');
  const [nombreUsuario, setnombreUsuario] = useState('');

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      console.log("data", data);
      console.log("data[0]", data[0]);
      setnombreUsuario(data[0].user.username);
      setDataPerfil(data);
      console.log('satos',dataPerfil);
      setFollowsMe(data[0].corporativo);
      setTipoIdentificacion(data[0].tipo_identificacion);
      setEmpresaName(data[0].nombre_empresa);
      setEmpresaNIT(data[0].nit);
      setIdentificacion(data[0].identificacion);
      setEditedData(data[0]); // Inicializar editedData con los datos del perfil
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  
  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleEdit = () => {
    setEditing(true);
    setEditedData(prevData => ({ ...prevData, ...dataPerfil }));
  };

  const handleEditIdentificacion = () => {
    setEditingIdentificacion(true);
    setEditedDataIdentificacion(prevData => ({ ...prevData, ...dataPerfil }));
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(apiUrl + "/api/user/" + user_id + "/update/", editedData);
      setEditing(false);
      setDataPerfil(editedData);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChangeIdentificacion = async () => {
    const formData = new FormData();
    formData.append('nombre_empresa', empresaName);
    formData.append('nit', empresaNIT);
    formData.append('tipo_identificacion', tipoIdentificacion);
    formData.append('identificacion', identificacion);
    formData.append('corporativo', followsMe);
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    const response = await axios.put(apiUrl + "/api/user/" + user_id + "/update/", formData);
    fetchUserProfile();
    setEditingIdentificacion(false);
  };
  
  const opcionesTipoIdentificacion = [
    { value: 'CC', label: 'Cédula de Ciudadanía' },
    { value: 'TI', label: 'Tarjeta de Identidad' },
    { value: 'CE', label: 'Cédula de Extranjería' },
    { value: 'PA', label: 'Pasaporte' },
  ];

  const opcionesPaises = [
    { value: 'Afganistán (+93)', label: 'Afganistán (+93)'},
  { value: 'Albania (+355)', label: 'Albania (+355)'},
  { value: 'Alemania (+49)', label: 'Alemania (+49)'},
  { value: 'Andorra (+376)', label: 'Andorra (+376)'},
  { value: 'Angola (+244)', label: 'Angola (+244)'},
  { value: 'Antigua y Barbuda (+1)', label: 'Antigua y Barbuda (+1)'},
  { value: 'Arabia Saudita (+966)', label: 'Arabia Saudita (+966)'},
  { value: 'Argelia (+213)', label: 'Argelia (+213)'},
  { value: 'Argentina (+54)', label: 'Argentina (+54)'},
  { value: 'Armenia (+374)', label: 'Armenia (+374)'},
  { value: 'Australia (+61)', label: 'Australia (+61)'},
  { value: 'Austria (+43)', label: 'Austria (+43)'},
  { value: 'Azerbaiyán (+994)', label: 'Azerbaiyán (+994)'},
  { value: 'Bahamas (+1)', label: 'Bahamas (+1)'},
  { value: 'Bangladés (+880)', label: 'Bangladés (+880)'},
  { value: 'Barbados (+1)', label: 'Barbados (+1)'},
  { value: 'Baréin (+973)', label: 'Baréin (+973)'},
  { value: 'Bélgica (+32)', label: 'Bélgica (+32)'},
  { value: 'Belice (+501)', label: 'Belice (+501)'},
  { value: 'Benin (+229)', label: 'Benin (+229)'},
  { value: 'Bielorrusia (+375)', label: 'Bielorrusia (+375)'},
  { value: 'Bolivia (+591)', label: 'Bolivia (+591)'},
  { value: 'Bosnia y Herzegovina (+387)', label: 'Bosnia y Herzegovina (+387)'},
  { value: 'Botsuana (+267)', label: 'Botsuana (+267)'},
  { value: 'Brasil (+55)', label: 'Brasil (+55)'},
  { value: 'Brunéi (+673)', label: 'Brunéi (+673)'},
  { value: 'Bulgaria (+359)', label: 'Bulgaria (+359)'},
  { value: 'Burkina Faso (+226)', label: 'Burkina Faso (+226)'},
  { value: 'Burundi (+257)', label: 'Burundi (+257)'},
  { value: 'Bután (+975)', label: 'Bután (+975)'},
  { value: 'Cabo Verde (+238)', label: 'Cabo Verde (+238)'},
  { value: 'Camboya (+855)', label: 'Camboya (+855)'},
  { value: 'Camerún (+237)', label: 'Camerún (+237)'},
  { value: 'Canadá (+1)', label: 'Canadá (+1)'},
  { value: 'Catar (+974)', label: 'Catar (+974)'},
  { value: 'Chad (+235)', label: 'Chad (+235)'},
  { value: 'Chile (+56)', label: 'Chile (+56)'},
  { value: 'China (+86)', label: 'China (+86)'},
  { value: 'Chipre (+357)', label: 'Chipre (+357)'},
  { value: 'Colombia (+57)', label: 'Colombia (+57)'},
  { value: 'Comoras (+269)', label: 'Comoras (+269)'},
  { value: 'Corea del Norte (+850)', label: 'Corea del Norte (+850)'},
  { value: 'Corea del Sur (+82)', label: 'Corea del Sur (+82)'},
  { value: 'Costa de Marfil (+225)', label: 'Costa de Marfil (+225)'},
  { value: 'Costa Rica (+506)', label: 'Costa Rica (+506)'},
  { value: 'Croacia (+385)', label: 'Croacia (+385)'},
  { value: 'Cuba (+53)', label: 'Cuba (+53)'},
  { value: 'Dinamarca (+45)', label: 'Dinamarca (+45)'},
  { value: 'Dominica (+1)', label: 'Dominica (+1)'},
  { value: 'Ecuador (+593)', label: 'Ecuador (+593)'},
  { value: 'Egipto (+20)', label: 'Egipto (+20)'},
  { value: 'El Salvador (+503)', label: 'El Salvador (+503)'},
  { value: 'Emiratos Árabes Unidos (+971)', label: 'Emiratos Árabes Unidos (+971)'},
  { value: 'Eritrea (+291)', label: 'Eritrea (+291)'},
  { value: 'Eslovaquia (+421)', label: 'Eslovaquia (+421)'},
  { value: 'Eslovenia (+386)', label: 'Eslovenia (+386)'},
  { value: 'España (+34)', label: 'España (+34)'},
  { value: 'Estados Unidos (+1)', label: 'Estados Unidos (+1)'},
  { value: 'Estonia (+372)', label: 'Estonia (+372)'},
  { value: 'Etiopía (+251)', label: 'Etiopía (+251)'},
  { value: 'Fiji (+679)', label: 'Fiji (+679)'},
  { value: 'Filipinas (+63)', label: 'Filipinas (+63)'},
  { value: 'Finlandia (+358)', label: 'Finlandia (+358)'},
  { value: 'Francia (+33)', label: 'Francia (+33)'},
  { value: 'Gabón (+241)', label: 'Gabón (+241)'},
  { value: 'Gambia (+220)', label: 'Gambia (+220)'},
  { value: 'Georgia (+995)', label: 'Georgia (+995)'},
  { value: 'Ghana (+233)', label: 'Ghana (+233)'},
  { value: 'Granada (+1)', label: 'Granada (+1)'},
  { value: 'Grecia (+30)', label: 'Grecia (+30)'},
  { value: 'Guatemala (+502)', label: 'Guatemala (+502)'},
  { value: 'Guinea (+224)', label: 'Guinea (+224)'},
  { value: 'Guinea Ecuatorial (+240)', label: 'Guinea Ecuatorial (+240)'},
  { value: 'Guinea-Bisáu (+245)', label: 'Guinea-Bisáu (+245)'},
  { value: 'Guyana (+592)', label: 'Guyana (+592)'},
  { value: 'Haití (+509)', label: 'Haití (+509)'},
  { value: 'Honduras (+504)', label: 'Honduras (+504)'},
  { value: 'Hungría (+36)', label: 'Hungría (+36)'},
  { value: 'India (+91)', label: 'India (+91)'},
  { value: 'Indonesia (+62)', label: 'Indonesia (+62)'},
  { value: 'Irak (+964)', label: 'Irak (+964)'},
  { value: 'Irán (+98)', label: 'Irán (+98)'},
  { value: 'Irlanda (+353)', label: 'Irlanda (+353)'},
  { value: 'Islandia (+354)', label: 'Islandia (+354)'},
  { value: 'Islas Marshall (+692)', label: 'Islas Marshall (+692)'},
  { value: 'Islas Salomón (+677)', label: 'Islas Salomón (+677)'},
  { value: 'Israel (+972)', label: 'Israel (+972)'},
  { value: 'Italia (+39)', label: 'Italia (+39)'},
  { value: 'Jamaica (+1)', label: 'Jamaica (+1)'},
  { value: 'Japón (+81)', label: 'Japón (+81)'},
  { value: 'Jordania (+962)', label: 'Jordania (+962)'},
  { value: 'Kazajistán (+7)', label: 'Kazajistán (+7)'},
  { value: 'Kenia (+254)', label: 'Kenia (+254)'},
  { value: 'Kirguistán (+996)', label: 'Kirguistán (+996)'},
  { value: 'Kiribati (+686)', label: 'Kiribati (+686)'},
  { value: 'Kuwait (+965)', label: 'Kuwait (+965)'},
  { value: 'Laos (+856)', label: 'Laos (+856)'},
  { value: 'Lesoto (+266)', label: 'Lesoto (+266)'},
  { value: 'Letonia (+371)', label: 'Letonia (+371)'},
  { value: 'Líbano (+961)', label: 'Líbano (+961)'},
  { value: 'Liberia (+231)', label: 'Liberia (+231)'},
  { value: 'Libia (+218)', label: 'Libia (+218)'},
  { value: 'Liechtenstein (+423)', label: 'Liechtenstein (+423)'},
  { value: 'Lituania (+370)', label: 'Lituania (+370)'},
  { value: 'Luxemburgo (+352)', label: 'Luxemburgo (+352)'},
  { value: 'Macedonia (+389)', label: 'Macedonia (+389)'},
  { value: 'Madagascar (+261)', label: 'Madagascar (+261)'},
  { value: 'Malasia (+60)', label: 'Malasia (+60)'},
  { value: 'Malaui (+265)', label: 'Malaui (+265)'},
  { value: 'Maldivas (+960)', label: 'Maldivas (+960)'},
  { value: 'Malí (+223)', label: 'Malí (+223)'},
  { value: 'Malta (+356)', label: 'Malta (+356)'},
  { value: 'Marruecos (+212)', label: 'Marruecos (+212)'},
  { value: 'Mauricio (+230)', label: 'Mauricio (+230)'},
  { value: 'Mauritania (+222)', label: 'Mauritania (+222)'},
  { value: 'México (+52)', label: 'México (+52)'},
  { value: 'Micronesia (+691)', label: 'Micronesia (+691)'},
  { value: 'Moldavia (+373)', label: 'Moldavia (+373)'},
  { value: 'Mónaco (+377)', label: 'Mónaco (+377)'},
  { value: 'Mongolia (+976)', label: 'Mongolia (+976)'},
  { value: 'Montenegro (+382)', label: 'Montenegro (+382)'},
  { value: 'Mozambique (+258)', label: 'Mozambique (+258)'},
  { value: 'Myanmar (+95)', label: 'Myanmar (+95)'},
  { value: 'Namibia (+264)', label: 'Namibia (+264)'},
  { value: 'Nauru (+674)', label: 'Nauru (+674)'},
  { value: 'Nepal (+977)', label: 'Nepal (+977)'},
  { value: 'Nicaragua (+505)', label: 'Nicaragua (+505)'},
  { value: 'Niger (+227)', label: 'Niger (+227)'},
  { value: 'Nigeria (+234)', label: 'Nigeria (+234)'},
  { value: 'Noruega (+47)', label: 'Noruega (+47)'},
  { value: 'Nueva Zelanda (+64)', label: 'Nueva Zelanda (+64)'},
  { value: 'Omán (+968)', label: 'Omán (+968)'},
  { value: 'Países Bajos (+31)', label: 'Países Bajos (+31)'},
  { value: 'Pakistán (+92)', label: 'Pakistán (+92)'},
  { value: 'Palaos (+680)', label: 'Palaos (+680)'},
  { value: 'Panamá (+507)', label: 'Panamá (+507)'},
  { value: 'Papúa Nueva Guinea (+675)', label: 'Papúa Nueva Guinea (+675)'},
  { value: 'Paraguay (+595)', label: 'Paraguay (+595)'},
  { value: 'Perú (+51)', label: 'Perú (+51)'},
  { value: 'Polonia (+48)', label: 'Polonia (+48)'},
  { value: 'Portugal (+351)', label: 'Portugal (+351)'},
  { value: 'Reino Unido (+44)', label: 'Reino Unido (+44)'},
  { value: 'República Centroafricana (+236)', label: 'República Centroafricana (+236)'},
  { value: 'República Checa (+420)', label: 'República Checa (+420)'},
  { value: 'República del Congo (+242)', label: 'República del Congo (+242)'},
  { value: 'República Democratica del Congo (+243)', label: 'República Democratica del Congo (+243)'},
  { value: 'República Dominicana (+1)', label: 'República Dominicana (+1)'},
  { value: 'Ruanda (+250)', label: 'Ruanda (+250)'},
  { value: 'Rumania (+40)', label: 'Rumania (+40)'},
  { value: 'Rusia (+7)', label: 'Rusia (+7)'},
  { value: 'Samoa (+685)', label: 'Samoa (+685)'},
  { value: 'San Cristóbal y Nieves (+1)', label: 'San Cristóbal y Nieves (+1)'},
  { value: 'San Marino (+378)', label: 'San Marino (+378)'},
  { value: 'San Vicente y las Granadinas (+1)', label: 'San Vicente y las Granadinas (+1)'},
  { value: 'Santa Lucía (+1)', label: 'Santa Lucía (+1)'},
  { value: 'Santo Tomé y Príncipe (+239)', label: 'Santo Tomé y Príncipe (+239)'},
  { value: 'Senegal (+221)', label: 'Senegal (+221)'},
  { value: 'Serbia (+381)', label: 'Serbia (+381)'},
  { value: 'Seychelles (+248)', label: 'Seychelles (+248)'},
  { value: 'Sierra Leona (+232)', label: 'Sierra Leona (+232)'},
  { value: 'Singapur (+65)', label: 'Singapur (+65)'},
  { value: 'Siria (+963)', label: 'Siria (+963)'},
  { value: 'Somalia (+252)', label: 'Somalia (+252)'},
  { value: 'Sri Lanka (+94)', label: 'Sri Lanka (+94)'},
  { value: 'Suazilandia (+268)', label: 'Suazilandia (+268)'},
  { value: 'Sudáfrica (+27)', label: 'Sudáfrica (+27)'},
  { value: 'Sudán (+249)', label: 'Sudán (+249)'},
  { value: 'Sudán del Sur (+211)', label: 'Sudán del Sur (+211)'},
  { value: 'Suecia (+46)', label: 'Suecia (+46)'},
  { value: 'Suiza (+41)', label: 'Suiza (+41)'},
  { value: 'Surinam (+597)', label: 'Surinam (+597)'},
  { value: 'Tailandia (+66)', label: 'Tailandia (+66)'},
  { value: 'Tanzania (+255)', label: 'Tanzania (+255)'},
  { value: 'Tayikistán (+992)', label: 'Tayikistán (+992)'},
  { value: 'Timor Oriental (+670)', label: 'Timor Oriental (+670)'},
  { value: 'Togo (+228)', label: 'Togo (+228)'},
  { value: 'Tonga (+676)', label: 'Tonga (+676)'},
  { value: 'Trinidad y Tobago (+1)', label: 'Trinidad y Tobago (+1)'},
  { value: 'Túnez (+216)', label: 'Túnez (+216)'},
  { value: 'Turkmenistán (+993)', label: 'Turkmenistán (+993)'},
  { value: 'Turquía (+90)', label: 'Turquía (+90)'},
  { value: 'Tuvalu (+688)', label: 'Tuvalu (+688)'},
  { value: 'Ucrania (+380)', label: 'Ucrania (+380)'},
  { value: 'Uganda (+256)', label: 'Uganda (+256)'},
  { value: 'Uruguay (+598)', label: 'Uruguay (+598)'},
  { value: 'Uzbekistán (+998)', label: 'Uzbekistán (+998)'},
  { value: 'Vanuatu (+678)', label: 'Vanuatu (+678)'},
  { value: 'Venezuela (+58)', label: 'Venezuela (+58)'},
  { value: 'Vietnam (+84)', label: 'Vietnam (+84)'},
  { value: 'Yemen (+967)', label: 'Yemen (+967)'},
  { value: 'Yibuti (+253)', label: 'Yibuti (+253)'},
  { value: 'Zambia (+260)', label: 'Zambia (+260)'},
  { value: 'Zimbabue (+263)', label: 'Zimbabue (+263)'},
  ];

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleCancelEditIdentificacion = () => {
    setEditingIdentificacion(false);
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar username={nombreUsuario}/>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={6}>
            <Card xl={12}>
              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftTypography variant="h6">
                    
                    Datos de la Persona/Empresa
                    {editingIdentificacion ? (
                      <>
                        <IconButton
                          variant="contained"
                          color="primary" // Puedes cambiar el color si lo deseas
                          aria-label="Guardar"
                          onClick={handleInputChangeIdentificacion}
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '60px',
                            backgroundColor: "rgb(131 128 128 / 41%)", // Cambia el color de fondo según tus preferencias
                            borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                            width: "40px", // Ajusta el ancho según tus necesidades
                            height: "40px" // Ajusta la altura según tus necesidades
                          }}
                        >
                          <SaveIcon onClick={() => {handleInputChangeIdentificacion}}/>
                        </IconButton>
                          <IconButton
                            variant="contained"
                            color="secondary" // Puedes cambiar el color si lo deseas
                            aria-label="Cancelar"
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              backgroundColor: "rgb(131 128 128 / 41%)", // Cambia el color de fondo según tus preferencias
                              borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                              width: "40px", // Ajusta el ancho según tus necesidades
                              height: "40px" // Ajusta la altura según tus necesidades
                            }}
                        >
                          <CancelIcon onClick={handleCancelEditIdentificacion} />
                        </IconButton>
                      </>
                    ) : (
                      
                        <IconButton
                          variant="contained"
                          color="primary" // Puedes cambiar el color si lo deseas
                          aria-label="editar"
                          // onClick={() => {handleEditIdentificacion}} 
                          onClick={handleEditIdentificacion}
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: "rgb(131 128 128 / 41%)", // Cambia el color de fondo según tus preferencias
                            borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                            width: "40px", // Ajusta el ancho según tus necesidades
                            height: "40px" // Ajusta la altura según tus necesidades
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      
                    )}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" py={1} pr={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      {editingIdentificacion ? (
                        <>
                        <SoftBox display="flex" py={1} mb={2}>
                          <SoftBox mb={3} mt={0.25}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </SoftBox>
                          <SoftBox width="80%" ml={2}>
                              <>
                                <Grid container spacing={2} sx={{ position: "relative", ml: "auto" }}>
                                  <Grid item xs={6}>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                    Tipo de Identificación
                                    </SoftTypography>
                                    <Select
                                      name="tipo_identificacion"
                                      value={tipoIdentificacion}
                                      onChange={(value) => setTipoIdentificacion(value)}
                                      // disabled={!followsMe}
                                      fontWeight="regular"
                                      className="MuiInputBase-input css-10ukfau-MuiInputBase-input css-y9gdep-MuiInputBase-root"    
                                    > 
                                      {opcionesTipoIdentificacion.map((opcion) => (
                                        <Select.Option key={opcion.value} value={opcion.value}>
                                          {opcion.label}
                                        </Select.Option>
                                      ))}         
                                    </Select>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                      Número de Identificación
                                    </SoftTypography>
                                    <SoftInput
                                      name="identificacion"
                                      type="number"
                                      label="Número de Identificación"
                                      value={identificacion}
                                      onChange={(e) => setIdentificacion(e.target.value)}
                                      // disabled={!followsMe}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" py={1} mb={2}>
                          <SoftBox mb={3} mt={0.25}>
                            &nbsp;&nbsp;&nbsp;
                            <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
                          </SoftBox>
                          <SoftBox width="80%" ml={2}>
                            <SoftTypography variant="button" fontWeight="regular" color="text">
                              Es corporativo?
                            </SoftTypography>
                          
                            {followsMe && (
                              <>
                                <Grid container spacing={2} sx={{ position: "relative", ml: "auto" }}>
                                  <Grid item xs={6}>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                      Nombre de la empresa
                                    </SoftTypography>
                                    <SoftInput
                                      name="empresa_nombre"
                                      label="Nombre de la empresa"
                                      value={empresaName}
                                      onChange={(e) => setEmpresaName(e.target.value)}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                      NIT
                                    </SoftTypography>
                                    <SoftInput
                                      type="number"
                                      name="nit"
                                      label="NIT de la empresa"
                                      value={empresaNIT}
                                      onChange={(e) => setEmpresaNIT(e.target.value)}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </SoftBox>
                        </SoftBox>
                        </>
                      ) : (
                        <>
                          <SoftBox display="flex" py={1} mb={2}>
                            <SoftBox mb={3} mt={0.25}>
                            </SoftBox>
                            <SoftBox width="80%" ml={2}>
                                <>
                                  <Grid container spacing={2} sx={{ position: "relative", ml: "auto" }}>
                                    <Grid item xs={6}>
                                      <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Tipo de identificación:
                                      </SoftTypography>
                                      <SoftTypography variant="button" fontWeight="regular" color="text">
                                        &nbsp;{tipoIdentificacion ? tipoIdentificacion : "Sin tipo"}
                                      </SoftTypography>                              
                                    </Grid>
                                    <Grid item xs={6}>
                                    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Número de identificación:
                                      </SoftTypography>
                                      <SoftTypography variant="button" fontWeight="regular" color="text">
                                        &nbsp;{identificacion ? identificacion : "Sin Identificación"}
                                      </SoftTypography> 
                                    </Grid>
                                  </Grid>
                                </>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox display="flex" py={1} mb={2}>
                            <SoftBox width="80%" ml={2}>
                              <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                Es corporativo? 
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                &nbsp;No &nbsp;
                                  </SoftTypography><Switch checked={followsMe} disabled={true} /> 
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                &nbsp;Si &nbsp;
                                </SoftTypography>
                              </SoftTypography>
                            
                              {followsMe && (
                                <>
                                  <Grid container spacing={2} sx={{ position: "relative", ml: "auto" }}>
                                    <Grid item xs={6}>
                                      <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Nombre de la empresa:
                                      </SoftTypography>
                                      <SoftTypography variant="button" fontWeight="regular" color="text">
                                      &nbsp;{empresaName ? empresaName : "Sin empresa"}
                                      </SoftTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        NIT:
                                      </SoftTypography>
                                      <SoftTypography variant="button" fontWeight="regular" color="text">
                                        &nbsp;{empresaNIT ? empresaNIT : "Sin NIT"}
                                      </SoftTypography>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </>
                      )}
                      <br></br>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {/* {dataPerfil && ( */}
              <>
                <ProfileInfoCard
                  title="Información de Perfil"
                  description={
                    editing ? (
                      <SoftInput
                        name="descripcion"
                        value={editedData.descripcion}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <>
                      {dataPerfil && (
                        <>
                          {dataPerfil.descripcion ? dataPerfil.descripcion : ""}
                        </>
                      )}
                      </>
                    )
                  }
                  location={
                    editing ? (
                      <>
                      <Select
                        name="pais"
                        value={dataPerfil.pais}
                        onChange={(value) => handleInputChange({ target: { name: "pais", value } })}

                        fontWeight="regular"
                        fullWidth
                      >
                        {opcionesPaises.map((pais) => (
                          <Select.Option key={pais.value} value={pais.value}>
                            {pais.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </>
                    ) : (
                      <>
                      {dataPerfil && (
                        <>
                          {dataPerfil.pais ? dataPerfil.pais : ""}
                        </>
                      )}
                      </>
                    )
                  }
                  info={{
                    mobile: editing ? (
                      <SoftInput
                        name="telefono"
                        value={editedData.telefono}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <>
                      {dataPerfil && (
                        <>
                          {dataPerfil.telefono ? dataPerfil.telefono : ""}
                        </>
                      )}
                      </>
                    )
                  }}
                  social={[
                    {
                      link: dataPerfil && (
                              dataPerfil.linkedin_url ? dataPerfil.linkedin_url : "https://www.linkedin.com/"
                              ),
                      icon: <LinkedInIcon />,
                      color: "linkedin",
                      editing: editing,
                      value_editing: editedData.linkedin_url,
                      name: "linkedin_url",
                      onchange: handleInputChange
                    },
                    {
                      link: dataPerfil && (
                              dataPerfil.facebook_url ? dataPerfil.facebook_url : "https://www.facebook.com/"
                            ),
                      icon: <FacebookIcon />,
                      color: "facebook",
                      editing: editing,
                      value_editing: editedData.facebook_url,
                      name: "facebook_url",
                      onchange: handleInputChange
                    },
                    {
                      link: dataPerfil && (
                        dataPerfil.instagram_url ? dataPerfil.instagram_url : "https://www.instagram.com/"
                        ),
                      icon: <InstagramIcon />,
                      color: "instagram",
                      editing: editing,
                      value_editing: editedData.instagram_url,
                      name: "instagram_url",
                      onchange: handleInputChange
                    },
                    {
                      link: dataPerfil && (
                        dataPerfil.twitter_url ? dataPerfil.twitter_url : "https://twitter.com"
                      ),
                      icon: <TwitterIcon />,
                      color: "twitter",
                      editing: editing,
                      value_editing: editedData.twitter_url,
                      name: "twitter_url",
                      onchange: handleInputChange
                    },
                  ]}
                  action={{
                    condition: editing,
                    oncancel: handleCancelEdit,
                    onchange: handleEdit,
                    onsave: handleSaveChanges,
                  }}
                />
              </>
            {/* )} */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
        </Grid>
      </SoftBox>
      <SoftBox mb={3} mt={0.25}></SoftBox>
      <Card xl={12}>
        <TableProfile />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
